import React from 'react'
import { useState, useEffect } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import SlideOut from '../../../components/SlideOut'
import ViewRequest from '../../../components/ViewRequest'
import Contact from '../../../components/Contact'
import useAuth from '../../../hooks/useAuth'
import Pagination from '../../../components/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import {
  faMagnifyingGlass,
  faPlus,
  faTimes,
  faSort,
  faSortUp,
  faSortDown,
  faSpinner
} from '@fortawesome/free-solid-svg-icons'
import CancelRequest from '../../../components/CancelRequest'

const Dashboard = () => {
  const axios = useAxiosPrivate()
  const { auth, setShowLoader } = useAuth()
  const [loadingCurrentPolicy, setLoadingCurrentPolicy] = useState(false)
  const [loadingPreviousPolicy, setLoadingPreviousPolicy] = useState(false)

  const [selectedSchool, setSelectedSchool] = useState("")
  const [orgInfo, setOrgInfo] = useState('')

  const [selectedRequest, setSelectedRequest] = useState(null)
  const [open, setOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)

  const [policyView, setPolicyView] = useState('current')
  const [previousPolicy, setPreviousPolicy] = useState(false)

  const [allRequests, setAllRequests] = useState([])
  const [allPreviousRequests, setAllPreviousRequests] = useState([])
  const [serviceRequests, setServiceRequests] = useState([])
  const [previousServiceRequests, setPreviousServiceRequests] = useState([])
  const [filteredRequests, setFilteredRequests] = useState([])
  const [currentRequests, setCurrentRequests] = useState([])
  const [uniqueAcademyNames, setUniqueAcademyNames] = useState([])

  const [recordViewType, setRecordViewType] = useState('current')
  const [searchQuery, setSearchQuery] = useState('')
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null); // 'asc', 'desc', or null

  const [recordsPerPage] = useState(10)
  const [indexOfLastRecord, setIndexOfLastRecord] = useState(10)
  const [indexOfFirstRecord, setIndexOfFirstRecord] = useState(0)
  const [nPages, setNumberOfPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    try {
      setLoadingCurrentPolicy(true)
      setLoadingPreviousPolicy(true)
      if (auth?.currentPolicy) {
        getServiceRequests(auth.currentPolicy.policy_id)
        fetchAllRecords(auth.currentPolicy.policy_id)
      }
  
      setOrgInfo(JSON.parse(localStorage.getItem('orgType')))
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      getPreviousPolicy()
    }
  }, [auth])

  useEffect(() => {
    filterRequests();
  }, [sortField, sortOrder, allRequests, serviceRequests, allPreviousRequests, previousServiceRequests, searchQuery, selectedSchool])

  useEffect(() => {
    setSortField(null)
    setSortOrder(null)
  }, [recordViewType, policyView])

  const fetchAllRecords = async (policyId, previous = false) => {
    try {
      const response = await axios.get(
        `/service-requests/all/${policyId}/${auth.user.contact_id}`
      )

      if (response.data && response.data.requests) {
        let data = response.data.requests
        data = data.map((item) => {
          return {
            ...item,
            request_info_array: JSON.parse(item.request_information || '{}'),
          };
        });

        if(previous) {
          setAllPreviousRequests(data)
        } else {
          setAllRequests(data)
        }
      }
    } catch (error) {
      setLoadingCurrentPolicy(false)
      console.error('Failed to fetch all records:', error)
    }
  }

  const getServiceRequests = async (policyId, previous = false) => {
    setShowLoader(true)
    try {
      await axios
        .get(
          `/service-requests/${policyId}/${auth.user.contact_id}`
        )
        .then((response) => {
          if (response.data.requests) {
            let requests = response.data.requests
            requests = requests.map((item) => {
              return {
                ...item,
                request_info_array: JSON.parse(item.request_information || '{}'),
              };
            });
            if(previous) {
              setPreviousServiceRequests(requests)
            } else {
              setServiceRequests(requests)
            }
          }
        })
        .catch((e) => {})
    } catch (error) {
      console.error('Error fetching service requests', error)
    } finally {
      setLoadingCurrentPolicy(false)
      setShowLoader(false)
    }
  }

  const getPreviousPolicy = async () => {
    try {
      const response = await axios.post('/staff-hub/previous-policy', {
        token: auth.token,
        orgId: auth.schools[0].id ?? auth?.schools[0].id,
        user: auth?.user?.contact_id,
      })
  
      if (response.data.error !== true) {
        setPreviousPolicy(response.data.result?.data.policy)
        getServiceRequests(response.data.result?.data.policy.policy_id, true)
        fetchAllRecords(response.data.result?.data.policy.policy_id, true)
      } else {
        console.error('Error in response:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching previous policy:', error)
    } finally {
      setLoadingPreviousPolicy(false)
    }
  }

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : sortOrder === 'desc' ? null : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const filterRequests = () => {
    let requests = [];

    if (recordViewType === 'all') {
      if(policyView === 'previous') {
        requests = [...allPreviousRequests];
      } else {
        requests = [...allRequests];
      }
    } else if (recordViewType === 'current') {
      if(policyView === 'previous') {
        requests = [...previousServiceRequests];
      } else {
        requests = [...serviceRequests];
      }
    }

    if(recordViewType === 'all') {
      requests = selectedSchool ? requests.filter((e) => {
        return e.request_info_array.staff_details?.academy_name.toLowerCase().trim() === selectedSchool.toLowerCase().trim()
      }) : requests;
    }
  
    if (searchQuery.trim().length > 0) {
      requests = requests.filter((e) =>
        e.request_info_array.claim_reference.toLowerCase().trim().includes(searchQuery.toLowerCase().trim()) ||
        e.request_info_array.staff_details?.staff_name.toLowerCase().trim().includes(searchQuery.toLowerCase().trim()) ||
        e.request_status.toLowerCase().trim().includes(searchQuery.toLowerCase().trim()) ||
        e.created_by_name.toLowerCase().trim().includes(searchQuery.toLowerCase().trim()) ||
        moment(e.request_info_array.staff_details?.start_date).format('DD/MM/YYYY').includes(searchQuery.trim()) ||
        moment(e.created_at).format('DD/MM/YYYY').includes(searchQuery.trim())
      );
    }

    if (sortField && sortOrder) {
      requests.sort((a, b) => {
        let aValue, bValue;
  
        switch (sortField) {
          case 'claim_reference':
            aValue = a.request_info_array.claim_reference?.toLowerCase() || '';
            bValue = b.request_info_array.claim_reference?.toLowerCase() || '';
            break;
          case 'academy_name':
            aValue = a.request_info_array.staff_details.academy_name?.toLowerCase() || '';
            bValue = b.request_info_array.staff_details.academy_name?.toLowerCase() || '';
            break;
          case 'staff_name':
            aValue = a.request_info_array.staff_details.staff_name?.toLowerCase() || '';
            bValue = b.request_info_array.staff_details.staff_name?.toLowerCase() || '';
            break;
          case 'start_date':
            aValue = moment(a.request_info_array.staff_details.start_date || '');
            bValue = moment(b.request_info_array.staff_details.start_date || '');
            break;
          case 'status':
            aValue = a.request_status?.toLowerCase() || '';
            bValue = b.request_status?.toLowerCase() || '';
            break;
          case 'created_by':
            aValue = a.created_by_name?.toLowerCase() || '';
            bValue = b.created_by_name?.toLowerCase() || '';
            break;
          case 'created_at':
            aValue = moment(a.created_at);
            bValue = moment(b.created_at);
            break;
          default:
            aValue = '';
            bValue = '';
        }
  
        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }
  
    // Update state with the filtered and sorted requests
    setFilteredRequests(requests);
    setCurrentRequests(requests.slice(indexOfFirstRecord, indexOfLastRecord));
    setNumberOfPages(Math.ceil(requests.length / recordsPerPage));
    getUniqueAcademyNames();
  };

  const getUniqueAcademyNames = () => {
    let academyNames = []
    if(policyView === 'current') {
      academyNames = [
        ...new Set(
          allRequests.map((item) => {
            const requestInfo = JSON.parse(item.request_information || '{}')
            return requestInfo.staff_details?.academy_name || ''
          })
        ),
      ].filter(Boolean)
    } else {
      academyNames = [
        ...new Set(
          allPreviousRequests.map((item) => {
            const requestInfo = JSON.parse(item.request_information || '{}')
            return requestInfo.staff_details?.academy_name || ''
          })
        ),
      ].filter(Boolean)
    }

    setUniqueAcademyNames(academyNames)
  }

  const openRequest = (request = null) => {
    setSelectedRequest(request)
    setOpen(true)
  }

  const handleViewRequest = (request) => {
    setSelectedRequest(request)
    setModalOpen(true)
  }

  const handleCancelRequest = (request) => {
    setSelectedRequest(request)
    setCancelModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
    setCancelModalOpen(false)
    setSelectedRequest(null)
  }

  const cancelRequestClicked = () => {
    setCancelModalOpen(false)
    setShowLoader(true)
    try {
      axios
        .post(`/service-requests/cancel/${selectedRequest.service_request_id}`)
        .then((response) => {
          if (!response.data.error) {
            closeModal()
            if(policyView === 'current') {
              getServiceRequests(auth.currentPolicy.policy_id)
            } else {
              getServiceRequests(previousPolicy?.policy_id, true)
            }
          }
        })
    } catch (error) {
      console.error('Failed to cancel request:', error)
    }
    setShowLoader(false)
  }

  const clearFilter = () => {
    setSelectedSchool('')
    setSearchQuery('')
    filterRequests()
    setCurrentPage(1)
    setCurrentRequests(serviceRequests.slice(0, recordsPerPage))
    setNumberOfPages(Math.ceil(serviceRequests.length / recordsPerPage))
  }

  const nextPage = () => {
    if (currentPage < nPages) {
      const newPage = currentPage + 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRequests(filteredRequests.slice(indexFirst, indexLast))
    }
  }
  const previousPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1
      const indexFirst = newPage * 10 - 10
      const indexLast = newPage * 10

      setIndexOfFirstRecord(indexFirst)
      setIndexOfLastRecord(indexLast)
      setCurrentPage(newPage)
      setCurrentRequests(filteredRequests.slice(indexFirst, indexLast))
    }
  }

  const statusClass = (status) => {
    if (typeof status !== 'string') {
      return 'bg-gray-200 text-gray-800'
    }

    switch (status.toLowerCase()) {
      case 'form completed - awaiting result':
      case 'no barrier to work':
      case 'no action required':
        return 'bg-green-200 text-green-800 border-green-400'
      case 'awaiting form completion':
      case 'referral needed':
      case 'in progress':
        return 'bg-amber-200 text-amber-800 border-amber-400'
      case 'closed':
      case 'form expired - start date lapsed':
      case 'referral cancelled':
        return 'bg-rose-200 text-rose-800 border-rose-400'
      default:
        if (status.toLowerCase().startsWith('form completed -')) {
          return 'bg-green-200 text-green-800 border-green-400'
        } else {
          return 'bg-gray-200 text-gray-800'
        }
    }
  }

  return (
    <div className="">
      <div className="mb-8 sm:mb-10">
        <h3 className="text-2xl sm:text-3xl md:text-5xl font-bold text-white text-center pt-12">
          Pre-Placement Questionnaires (PPQ)
        </h3>
        <h4 className="text-lg font-medium text-white text-center">
          Create and Manage your Pre-Placement Questionnaire Requests here.
        </h4>
      </div>
      <div className="flex w-full">
        <button
          className={`w-1/8 py-2 px-2 mr-2 text-sm rounded-t-md text-center flex items-center font-bold ${
            policyView === 'current' ? 'bg-white' : 'bg-gray-200'
          }`}
          onClick={() => setPolicyView('current')}
        >
          <p>Current Policy</p>
          {loadingCurrentPolicy && (
            <FontAwesomeIcon className="ml-2" icon={faSpinner} spin />
          )}
        </button>
        {previousPolicy && (
          <button
            className={`w-1/8 py-2 px-2 mr-2 text-sm rounded-t-md text-center flex items-center font-bold ${
              policyView === 'previous' ? 'bg-white' : 'bg-gray-200'
            }`}
            onClick={() => setPolicyView('previous')}
          >
            <p>Previous Policy</p>
            {loadingPreviousPolicy && (
              <FontAwesomeIcon className="ml-2" icon={faSpinner} spin />
            )}
          </button>
        )}
      </div>
      <div className="bg-white rounded-tr-md rounded-br-md rounded-bl-md p-4 w-full mx-auto shadow-md">
        <div className="pb-4 flex items-end justify-between ">
          <div className="flex items-center space-x-2">
            <div className="flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md h-11 ">
              <div className="flex items-center pl-3 pointer-events-none text-gray-600">
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                />
              </div>
              <input
                type="text"
                id="table-search"
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyUp={filterRequests}
                className="bg-gray-50 w-80 pl-6"
                placeholder="Search Requests"
              />
            </div>
            {auth.user.master_oh === 1 && recordViewType === 'all' && (
              <div className="flex items-center">
                <div className="dropdown-container relative inline-block">
                  <select
                    defaultValue={selectedSchool}
                    onChange={(e) => {setSelectedSchool(e.target.value)}}
                    className="dropdown-filter border border-gray-300 rounded-md px-3 py-2 pr-10 focus:outline-none focus:ring-2 focus:ring-teal-600"
                  >
                    <option value="">All Schools</option>
                    {uniqueAcademyNames.map((academy, index) => (
                      <option key={index} value={academy}>
                        {academy}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedSchool && (
                  <div
                    onClick={clearFilter}
                    className="border flex items-center rounded h-11 border-red-700 cursor-pointe ml-2 cursor-pointer"
                  >
                    <div className="text-red-700 h-full flex justify-center items-center px-6 font-medium">
                      Clear Filters
                    </div>
                    <div className="bg-red-600 text-white h-full flex w-[45px] justify-center items-center">
                      <FontAwesomeIcon icon={faTimes} className="pl-2" />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="mt-4 hover:shadow-md">
            <button
              type="submit"
              className="button border-[#02ADAD]"
              onClick={() => openRequest()}
            >
              <span className="text-[#02ADAD]">Submit Request</span>
              <span className="bg-[#02ADAD]">
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </button>
          </div>
        </div>
        <div className="overflow-hidden mt-4">
          {auth.user.master_oh === 1 && (
            <div className="flex items-center space-x-2 mb-4">
              <button
                onClick={() => setRecordViewType('current')}
                className={`${
                  recordViewType === 'current' ? 'bg-[#02ADAD]' : 'bg-gray-400'
                } hover:bg-teal-600 rounded-lg px-4 py-2 text-white cursor-pointer`}
              >
                Current PPQ ({ policyView === 'current' ? serviceRequests.length : previousServiceRequests.length })
              </button>
              <button
                onClick={() => setRecordViewType('all')}
                className={`${
                  recordViewType === 'all' ? 'bg-[#02ADAD]' : 'bg-gray-400'
                } hover:bg-teal-600 rounded-lg px-4 py-2 text-white cursor-pointer`}
              >
                All PPQs ({policyView === 'current' ? allRequests.length : allPreviousRequests.length})
              </button>
            </div>
          )}
          <table className="table-main rounded-b-md rounded-tr-lg">
            <thead className="border-b font-medium dark:border-slate-500 bg-slate-100">
              <tr className="bg-[#02ADAD]">
                <th
                  scope="col"
                  className="cursor-pointer select-none"
                  onClick={() => handleSort('claim_reference')}
                >
                  <div className="flex justify-center items-center">
                    <div className="mr-1">
                      Reference
                    </div>
                    {sortField !== 'claim_reference' ? (
                      <FontAwesomeIcon icon={faSort} />
                    ) : (
                      <FontAwesomeIcon icon={sortOrder === 'asc' ? faSortUp : sortOrder === 'desc' ? faSortDown : faSort} />
                    )}
                  </div>
                </th>
                {recordViewType === 'all' && (
                  <th scope="col"
                  className="cursor-pointer select-none"
                  onClick={() => handleSort('academy_name')}>
                    <div className="flex justify-center items-center">
                      <div className="mr-1">
                        Name
                      </div>
                      {sortField !== 'academy_name' ? (
                        <FontAwesomeIcon icon={faSort} />
                      ) : (
                        <FontAwesomeIcon icon={sortOrder === 'asc' ? faSortUp : sortOrder === 'desc' ? faSortDown : faSort} />
                      )}
                    </div>
                  </th>
                )}
                <th scope="col"
                  className="cursor-pointer select-none"
                  onClick={() => handleSort('staff_name')}>
                    <div className="flex justify-center items-center">
                      <div className="mr-1">
                        Staff Member
                      </div>
                      {sortField !== 'staff_name' ? (
                        <FontAwesomeIcon icon={faSort} />
                      ) : (
                        <FontAwesomeIcon icon={sortOrder === 'asc' ? faSortUp : sortOrder === 'desc' ? faSortDown : faSort} />
                      )}
                    </div>
                </th>
                <th scope="col"
                  className="cursor-pointer select-none"
                  onClick={() => handleSort('start_date')}>
                    <div className="flex justify-center items-center">
                      <div className="mr-1">
                        Start Date
                      </div>
                      {sortField !== 'start_date' ? (
                        <FontAwesomeIcon icon={faSort} />
                      ) : (
                        <FontAwesomeIcon icon={sortOrder === 'asc' ? faSortUp : sortOrder === 'desc' ? faSortDown : faSort} />
                      )}
                    </div>
                </th>
                <th scope="col"
                  className="cursor-pointer select-none"
                  onClick={() => handleSort('status')}>
                    <div className="flex justify-center items-center">
                      <div className="mr-1">
                        Status
                      </div>
                      {sortField !== 'status' ? (
                        <FontAwesomeIcon icon={faSort} />
                      ) : (
                        <FontAwesomeIcon icon={sortOrder === 'asc' ? faSortUp : sortOrder === 'desc' ? faSortDown : faSort} />
                      )}
                    </div>
                </th>
                <th scope="col">
                  Additional Info
                </th>
                {recordViewType === 'all' && (
                <th scope="col"
                  className="cursor-pointer select-none"
                  onClick={() => handleSort('created_by')}>
                    <div className="flex justify-center items-center">
                      <div className="mr-1">
                        Created By
                      </div>
                      {sortField !== 'created_by' ? (
                        <FontAwesomeIcon icon={faSort} />
                      ) : (
                        <FontAwesomeIcon icon={sortOrder === 'asc' ? faSortUp : sortOrder === 'desc' ? faSortDown : faSort} />
                      )}
                    </div>
                </th>
                )}
                <th scope="col"
                  className="cursor-pointer select-none"
                  onClick={() => handleSort('created_at')}>
                    <div className="flex justify-center items-center">
                      <div className="mr-1">
                        Created On
                      </div>
                      {sortField !== 'created_at' ? (
                        <FontAwesomeIcon icon={faSort} />
                      ) : (
                        <FontAwesomeIcon icon={sortOrder === 'asc' ? faSortUp : sortOrder === 'desc' ? faSortDown : faSort} />
                      )}
                    </div>
                </th>
                <th scope="col" className="py-4">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {currentRequests.length > 0 ? (
                currentRequests.map((item, index) => {

                  return (
                    <tr
                      key={index}
                      className="border-b transition duration-300 ease-in-out hover:bg-slate-100 dark:border-slate-500 dark:hover:bg-slate-600"
                    >
                      <td className="whitespace-nowrap px-2 py-4 font-medium">
                        {item.request_info_array.claim_reference ?? ''}
                      </td>
                      {recordViewType === 'all' && (
                        <td className="whitespace-nowrap px-2 py-4 font-medium">
                          {item.request_info_array.staff_details.academy_name || ''}
                        </td>
                      )}
                      <td className="whitespace-nowrap px-2 py-4 font-medium">
                        {item.request_info_array.staff_details.staff_name || ''}
                      </td>
                      <td className="whitespace-nowrap px-2 py-4 font-medium">
                        {item.request_info_array.staff_details.start_date
                          ? moment(item.request_info_array.staff_details.start_date).format('DD/MM/YYYY')
                          : 'N/A'}
                      </td>
                      <td className="text-xs py-3 w-1/4">
                        <span
                          className={`${statusClass(
                            item.request_status
                          )} rounded-full px-2 py-1 capitalize font-medium`}
                        >
                          {item.request_status}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-2 py-4 font-medium">
                        {item.description && item.description.length > 200
                          ? `${item.description.slice(0, 200)}...`
                          : item.description || ''}
                      </td>
                      {recordViewType === 'all' && (
                        <td className="whitespace-nowrap px-2 py-4 font-medium capitalize">
                          {item.created_by_name}
                        </td>
                      )}
                      <td className="whitespace-nowrap px-2 py-4 font-medium">
                        {moment(item.created_at).format('DD/MM/YYYY')}
                      </td>
                      <td className="whitespace-nowrap px-2 py-4 font-medium">
                        <button
                          className="text-blue-500 hover:text-blue-600"
                          onClick={() => handleViewRequest(item)}
                        >
                          View
                        </button>
                        {!item.is_complete && (
                          <button
                            className="text-rose-400 ml-4 hover:text-rose-600"
                            onClick={() => handleCancelRequest(item)}
                          >
                            Cancel
                          </button>
                        )}
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <p className="no-records">No Requests found</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            next={nextPage}
            prev={previousPage}
            first={indexOfFirstRecord}
            last={indexOfLastRecord}
            total={filteredRequests.length}
          />
        </div>
      </div>
      <div className="rounded-md bg-[#FFECD1] p-4 mt-16 border border-[#F8CF94]">
        <div className="flex">
          <div className="flex-shrink-0">
            {/* <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-green-400" /> */}
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-[#B57B29]">
              <strong>Please Note:</strong> All wellbeing service requests are
              confidential. Therefore any requests for access made by them will
              not show below. Presented here are the Pre-Placement
              Questionnaires created by you.
            </p>
          </div>
        </div>
      </div>
      <SlideOut
        open={open}
        setOpen={setOpen}
        auth={auth}
        requestData={selectedRequest}
        orgInfo={orgInfo}
      />
      <ViewRequest
        isOpen={modalOpen}
        onClose={closeModal}
        viewingRequest={selectedRequest}
      />

      <CancelRequest
        isOpen={cancelModalOpen}
        onClose={closeModal}
        onCancel={cancelRequestClicked}
        viewingRequest={selectedRequest}
      />
      <Contact />
    </div>
  )
}

export default Dashboard
